@import "./app/products/common/product-custom-themes.scss";
@import "./app/orders/common/basket-custom-themes.scss";

@mixin custom-components-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background-of-main);
  $forground: map-get($theme, forground-of-main);

  .form-control {
    background-color: $background;
    color: $forground;
    border-color: transparent;
  }
  input[type="number"].form-control::-webkit-outer-spin-button,
  input[type="number"].form-control::-webkit-inner-spin-button {
    opacity: 1;
  }

  @include product-components-theme($theme);
  @include basket-components-theme($theme);
}
