@mixin product-components-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background-of-main);
  $forground: map-get($theme, forground-of-main);

  .product_card {
    position: relative;
    width: 300px;
    height: 500px;
    border-radius: 2px;
    overflow: hidden;
    background: mat-color($primary);
    box-shadow: 0 0.25rem 0.25rem mat-color($primary),
      0 0 1rem mat-color($primary);
    color: mat-color($accent);

    &__image-container {
      margin: -1rem -1rem;
      background-color: mat-color($accent);
    }

    &:not(.highlight-card):hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 17px mat-color($primary);
    }

    &__line {
      stroke-width: 3;
      fill: transparent;
      opacity: 0;
      animation: LineFadeIn 0.8s 0.8s forwards ease-in;
    }

    &__image {
      opacity: 0;
      max-height: 300px;
      padding: 10px 10px 50px 10px;
      animation: ImageFadeIn 0.8s 1.4s forwards;
    }

    &__title {
      margin-top: 10px;
      font-weight: 800;
      letter-spacing: 0.01em;
      color: mat-color($accent);
    }

    &__content {
      margin-top: -1rem;
      padding: 10px;
      opacity: 0;
      animation: ContentFadeIn 0.8s 1.6s forwards;
      background: mat-color($primary);
    }

    &__svg {
      position: absolute;
      left: 0;
      top: 115px;
      stroke: transparent;
      fill: mat-color($primary);
    }
  }

  .bottom-right {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .bottom-left {
    position: absolute;
    margin-top: -8px;
    bottom: -10px;
    left: 10px;
    width: 50px;
  }

  .mat-form-field{
    width: 100px;
  }

  input{
    min-height: 2em;
  }

  @keyframes LineFadeIn {
    0% {
      opacity: 0;
      d: path(
        "M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 "
      );
      stroke: #fff;
    }
    50% {
      opacity: 1;
      d: path(
        "M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300"
      );
      stroke: #888bff;
    }
    100% {
      opacity: 1;
      d: path(
        "M 0 155 Q 65 250 65 250 Q 250 500 350 350 C 300 500 350 250 550 350 Q 750 450 800 400"
      );
      stroke: #545581;
    }
  }

  @keyframes ContentFadeIn {
    0% {
      transform: translateY(-1rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes ImageFadeIn {
    0% {
      transform: translate(-0.5rem, -0.5rem) scale(1.05);
      opacity: 0;
      filter: blur(2px);
    }
    50% {
      opacity: 1;
      filter: blur(2px);
    }
    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
      filter: blur(0);
    }
  }
}
