
@import '~@angular/material/theming';

@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
$md-tarffprimary: (
 
  50 : #e6f2f8,
  100 : #c2deee,
  200 : #99c9e3,
  300 : #70b3d8,
  400 : #51a2cf,
  500 : #3292c7,
  600 : #2d8ac1,
  700 : #267fba,
  800 : #1f75b3,
  900 : #1363a6,
  A100 : #d8ecff,
  A200 : #a5d3ff,
  A400 : #72b9ff,
  A700 : #58adff,
    contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
    )
);



$md-tarffaccent:(
  50 : #ffffff,
  100 : #ffffff,
  200 : #ffffff,
  300 : #ffffff,
  400 : #ffffff,
  500 : #ffffff,
  600 : #ffffff,
  700 : #ffffff,
  800 : #ffffff,
  900 : #ffffff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);
$tarff-primary:mat-palette($md-tarffprimary,100,50,200);
 $tarff-accent:mat-palette($md-tarffaccent,100,50,200);

//light theme
$light-custom-primary: mat-palette($tarff-primary);
$light-custom-accent: mat-palette($tarff-accent);
$light-custom-warn: mat-palette($mat-orange);
$light-custom-theme: mat-light-theme($light-custom-primary, $light-custom-accent, $light-custom-warn);

//dark themes
$dark-custom-primary: mat-palette($tarff-primary, 900);
$dark-custom-accent: mat-palette($mat-grey, A200);
$dark-custom-warn: mat-palette($mat-orange);

$dark-custom-theme: mat-dark-theme(
  (
    color: (
      primary: $dark-custom-primary,
      accent: $dark-custom-accent,
      warn: $dark-custom-warn
    ),
  )
);
