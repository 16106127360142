@mixin basket-components-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background-of-main);
  $forground: map-get($theme, forground-of-main);


  .basket_card {
    padding: 20px;
    margin: 30px;
    height: 200px;
    &__img_container {
      transform: skewY(-7deg);
      align-items: center;
      box-shadow: inset 0 0 1.5em mat-color($primary),
                        0 0  0 2px mat-color($primary),
                        0.3em 0.3em 1em grey;
    }
    &__details{
      display: flex;
      border-left: 3px double mat-color($primary);

      &_info{
        height: 100%;
        width: 100%;

        &_title{
          text-decoration: underline;
          margin: 8px;
        }
        &_calc{
          display: flex;
          margin-top: 20px;
        }
      }
    }

     img{
       max-height: 160px;
       padding: 20px;
     }
  }

  // label{
  //   font-size: 1vw;
  // }
}
